import React from "react"
import { Link } from "gatsby"
import { Box, Badge, Image, Grid } from "@chakra-ui/core"
import Layout from "../../../components/layoutSurpriseCustom"
import BgImg from "../../../images/TH_Big.jpg"
import TH from "../../../images/TH_Square.jpg"
import More from "../../../images/moreSermons.jpg"
import SEO from "../../../components/seo"
import { css } from "@emotion/core"

function SermonBox({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as={Link}
      to={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

function SermonBoxExternal({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as="a"
      href={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

const IndexPage = () => (
  <Layout
    ImgSrc={BgImg}
    MainText="Touching Holiness"
    SecondText="Check out sermons from our Touching Holiness sermon series"
  >
    <SEO title="Sermons - Touching Holiness" />
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
      ]}
      gap={10}
    >
                  <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="Hogs and Dogs"
        sermonSeries="Touching Holiness"
        sermonLink="/surprise/sermons/motivation-towards-holiness"
        date="July 9th, 2023"
        pastor="Pastor Preston"
      />
               <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="Hogs and Dogs"
        sermonSeries="Touching Holiness"
        sermonLink="/surprise/sermons/hogs-and-dogs"
        date="July 2nd, 2023"
        pastor="Pastor Jackie"
      />
              <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="Spiritual Supplements"
        sermonSeries="Touching Holiness"
        sermonLink="/surprise/sermons/spiritual-supplements"
        date="June 25th, 2023"
        pastor="Pastor Jackie"
      />
          <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="Leading God's Way"
        sermonSeries="Touching Holiness"
        sermonLink="/surprise/sermons/leading-gods-way"
        date="June 18th, 2023"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="Six Truths About Suffering"
        sermonSeries="Touching Holiness"
        sermonLink="/surprise/sermons/six-truths-about-suffering"
        date="June 11th, 2023"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="Holiness and Suffering"
        sermonSeries="Touching Holiness"
        sermonLink="/surprise/sermons/holiness-and-suffering"
        date="June 4th, 2023"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="A Church Pursuing Holiness"
        sermonSeries="Touching Holiness"
        sermonLink="/surprise/sermons/church-pursuing-holiness"
        date="May 28th, 2023"
        pastor="Pastor Chad"
      />
      <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="Holiness and Marriage"
        sermonSeries="Touching Holiness"
        sermonLink="/surprise/sermons/holiness-and-marriage"
        date="May 21st, 2023"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="Holiness and Work"
        sermonSeries="Touching Holiness"
        sermonLink="/surprise/sermons/holiness-and-work"
        date="May 14th, 2023"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="Live by Example"
        sermonSeries="Touching Holiness"
        sermonLink="/surprise/sermons/live-by-example"
        date="May 7th, 2023"
        pastor="Andrew Puett"
      />
      <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="The Issue of Identity"
        sermonSeries="Touching Holiness"
        sermonLink="/surprise/sermons/issue-of-identity"
        date="April 30th, 2023"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="Loving One Another"
        sermonSeries="Touching Holiness"
        sermonLink="/surprise/sermons/loving-one-another"
        date="April 24th, 2023"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="The Fiercest Verse in all the Bible"
        sermonSeries="Touching Holiness"
        sermonLink="/surprise/sermons/fiercest-verse"
        date="April 16th, 2023"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="A Whole 'Nother Level"
        sermonSeries="Touching Holiness"
        sermonLink="/surprise/sermons/whole-nother-level"
        date="April 9th, 2023"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={TH}
        imageAlt="Touching Holiness Sermon Series"
        sermonTitle="Hope for Holiness"
        sermonSeries="Touching Holiness"
        sermonLink="/surprise/sermons/hope-for-holiness"
        date="April 2nd, 2023"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={More}
        imageAlt="view more sermons"
        sermonTitle="View More Sermons"
        sermonLink="/surprise/sermons"
        sermonSeries="All Sermons"
        pastor="Cross Church Surprise"
      />
    </Grid>
  </Layout>
)

export default IndexPage
